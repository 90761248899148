<script>
	import Vue from 'vue';
	import GlobalVue from '../helper/Global.vue'
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				collection:{},
				static_hero:{},
			}
		},
		computed:{
			isLogin(){
				return this.user && Gen.userToken() ? true : false
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_the_collection,
					link: { name: 'BLCollection' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
			$(document).ready(() => {
				AOS.init()
				let heroHeight = $(window).height() / 1.75;
				$("#slider:not(.full-screen)").css("height", heroHeight + "px");
				$('#slider_content').css('top', '50px')
			})
			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});
			this.getCollection()
			this.getStatisHero()
		},
		methods: {
			getCollection() {
				Gen.apirest("/belli-collection", {}, (err,resp)=>{
					this.$root.topProgress.done()
					if(err) console.log(err)
					this.collection = resp.data
				})
			},
			getStatisHero() {
				Gen.apirest("/static-hero", {id:3}, (err,resp)=>{
					if(err) console.log(err)
					this.static_hero = resp.data
				})
			},
			countCollection(id) {
				Gen.apirest("/hit-count-collection", {id:id}, (err,resp)=>{
					if(err) console.log(err)
					console.log("success")
				})
			}
		},
		watch: {}
	};
</script>
<template>
	<div Content>
		<section id="slider" class="slider-element" data-aos="zoom-out" data-aos-duration="1000"
			:style="'background-image: url('+uploader(static_hero.image_desktop)+');background-position: 50% 0;background-size:cover;'">

			<div id="slider_content" class="container clearfix">
				<div class="slider-caption fx-top">
					<div class="heading-title">
						<h4 :style="'color:'+static_hero.header_color">{{static_hero.title_header}}</h4>
						<h2 :style="'color:'+static_hero.subheader_color">{{static_hero.title_subheader}}</h2>
					</div>
				</div>
			</div>

			<a href="javascript:;" data-scrollto="#content" class="scroll-down">
				<i class="icon-angle-down"></i>
			</a>
		</section>

		<div id="content">
			<section id="tastingNotes" class="section notopmargin nobottommargin">
				<div class="container">
					<BreadCrumb :data="breadCrumb"></BreadCrumb>
					<div v-for="(v,k) in collection" :key="k" :class="k%2?'taste-note rev-img':'taste-note'">
						<div class="row justify-content-center">
							<div class="col-md-6" :data-aos="k%2?'fade-left':'fade-right'" data-aos-duration="1500" :data-aos-anchor="'#anc'+k">
								<div class="tn-img img-alter">
									<img :src="uploader(v.image)" :alt="v.title">
								</div>
							</div>
							<div class="col-md-6" :data-aos="k%2?'fade-right':'fade-left'" :id="'anc'+k" data-aos-duration="1500">
								<div class="tn-desc">
									<h2> {{v.title}}</h2>
									<h4>{{web.lbl_tasting_note}}</h4>	
									<p v-html="v.description"></p>
									<router-link v-if="!isLogin" :to="{name:'Login'}" class="button button-red">{{v.button_title}}</router-link>
									<a v-if="isLogin" @click="countCollection(v.id)" :href="'https://wa.me/62'+v.message.phone+'?text='+v.message.message" target="_blank" class="button button-red">{{v.button_title}}</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>